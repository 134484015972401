<template>
  <div class="d-flex wrapper">
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="valueChange(value -= step)"
        class="btn-icon counter-btn"
        size="sm"
    >
      <feather-icon icon="MinusIcon"/>
    </b-button>
    <b-form-input
        id="qweqweqwe"
        :size="size"
        :value="value"
        type="number"
        min="0"
        class="input-wrapper text-center col-4 rounded-0"
        number
        @change="valueChange($event)"
        style="min-width: 60px"
    />
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="valueChange(value += step)"
        class="btn-icon counter-btn"
        size="sm"
    >
      <feather-icon icon="PlusIcon"/>
    </b-button>
  </div>
</template>

<script>
import {
  BIcon,
  BIconDash,
  BIconPlus,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BFormInput
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'BlSpinButton',
  directives: {
    Ripple,
  },
  components: {
    BIcon,

    /* eslint-disable vue/no-unused-components */
    BIconDash,
    BIconPlus,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton, BFormInput,
  },

  props: {
    size: {
      type: String,
      required: false,
      default: 'md',
      validator: function (value) {
        return ['sm', 'md', 'lg'].includes(value)
      }
    },
    value: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
      default: 999999
    },
    step: {
      type: Number,
      required: false,
      default: 1
    }
  },

  methods: {
    valueChange(newValue) {
      newValue = parseFloat(newValue)
      if (newValue <= 0) {
        this.$emit('input', 0)
      } else {
        if (newValue >= this.max) {
          this.$emit('input', this.max)

        } else {
          this.$emit('input', newValue)

        }

      }
    }
  }
}
</script>

<style scoped>
.wrapper {
  align-items: center;
  justify-content: center;
}

.input-wrapper {
  border: none;
  outline: none;
}
</style>
