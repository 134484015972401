<template>
  <div>
    <b-overlay :show="dataLoading" rounded="sm">
      <ModalCustomProduct @added="addNewCustomProduct"
                          :is-active="isActiveCustomProduct"
                          :zone="zone"
      ></ModalCustomProduct>
      <ModalViewProduct
          @updated="addNewCustomProduct; isActiveViewProduct = false"
          :is-active="isActiveViewProduct"
          :product="this.productItem"
      ></ModalViewProduct>

      <!-- Зона level-1 {кнопки} -->
      <b-row class="align-items-center justify-content-between w-100 m-0 flex-nowrap pl-2 pr-2"
             style=" box-sizing: border-box; padding-top: 10px; padding-bottom: 10px; border-top: 1px solid #ddd">
        <h4
            class="flex-grow-1 m-0"
            v-if="editing == false"
            style="max-width: 600px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
            @dblclick="renameZone">
          {{ this.zone.title }}
        </h4>
        <b-col cols="12" md="3" v-if="editing">
          <b-form-input class="mb-2 mb-sm-0" v-model="name" @blur="blurNameZone"></b-form-input>
        </b-col>
        <b-col cols="12" md="3" v-if="changePosition">
          <b-form-input class="mb-2 mb-sm-0" v-model="position" @blur="setProductPosition"></b-form-input>
        </b-col>
        <b-button-group class="m-0 bg-primary rounded"
                        style="background-color: #F3F2F7 !important; color: #000000 !important;">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="upZone"
              v-b-tooltip.hover title="Переместить зону выше по иерархии проекта"
              style="background-color: #F3F2F7 !important; color: #000000 !important; border: none"
          >
            <feather-icon icon="ArrowUpIcon" size="20"/>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Добавить товар в эту зону"
              @click="goToPickProducts"
              style="background-color: #F3F2F7 !important; color: #000000 !important; border: none "
          >
            <feather-icon icon="GridIcon" size="20"/>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Добавить кастомный товар"
              @click="isActiveCustomProduct =! isActiveCustomProduct"
              style="background-color: #F3F2F7 !important; color: #000000 !important; border: none"
          >
            <feather-icon icon="SlidersIcon" size="20"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Изменить наименование зоны"
              @click="renameZone"
              style="background-color: #F3F2F7 !important; color: #000000 !important; border: none"
          >
            <feather-icon icon="EditIcon" size="20"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Оставить комментарий"
              @click="leaveZoneComment"
              style="background-color: #F3F2F7 !important; color: #000000 !important; border: none"
          >
              <feather-icon icon="MessageSquareIcon" size="20"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Дублировать зону"
              @click="copyZone"
              style="background-color: #F3F2F7 !important; color: #000000 !important; border: none"
          >
            <feather-icon icon="CopyIcon" size="20"/>
          </b-button>
          <b-button
              v-if="$store.state.productClipboard.productIds.length > 0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Добавить скопированные товары в эту зону"
              @click="insertCopiedItems"
              style="background-color: #F3F2F7 !important; color: #000000 !important; border: none"

          >
            <feather-icon icon="AirplayIcon" size="20"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Скопировать зону в другое помещение"
              @click="copyZoneId"
              style="background-color: #F3F2F7 !important; color: #000000 !important; border: none"
          >
            <feather-icon icon="CornerDownLeftIcon" size="20"/>
          </b-button>
          <b-button
              v-if="zone.is_visible == true"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Скрыть зону"
              @click="changeVisibleZone"
              style="background-color: #F3F2F7 !important; color: #000000 !important; border: none"
          >
            <feather-icon icon="EyeOffIcon" size="20"/>
          </b-button>
          <b-button
              v-if="zone.is_visible == false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Показать зону"
              @click="changeVisibleZone"
              style="background-color: #F3F2F7 !important; color: #000000 !important; border: none"
          >
            <feather-icon icon="EyeIcon" size="20"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover title="Удалить зону"
              @click="deleteZone"
              style="background-color: #F3F2F7 !important; color: #000000 !important; border: none"
          >
            <feather-icon icon="Trash2Icon" size="20"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="downZone"
              v-b-tooltip.hover title="Переместить зону ниже по иерархии проекта"
              style="background-color: #F3F2F7 !important; color: #000000 !important; border: none"
          >
            <feather-icon icon="ArrowDownIcon" size="20"/>
          </b-button>
        </b-button-group>
      </b-row>

      <b-row class="p-1" v-if="commenting">
          <b-col cols="12" md="12">
              <b-form-input
                  class="mb-2 mb-sm-0"
                  v-model="message"
                  @blur="blurCommentZone"
                  :placeholder="zone.comment ? zone.comment : 'Комментарий'"
              >
              </b-form-input>
          </b-col>
      </b-row>
      <b-row class="p-1" v-if="!commenting && zone.comment">
          <b-col cols="12" md="12">
              <p
                  class="flex-grow-1 m-0"
                  style="font-weight: bold"
              >
                  Комментарий по зоне:
                  <span style="font-weight: normal">{{ this.zone.comment }}</span>
              </p>
          </b-col>
      </b-row>

      <transition name="fade" tag="table">
        <b-table
            v-if="zone.is_visible"
            ref="refUserListTable"
            class="position-relative"
            small
            :items="sortItems"
            responsive
            :fields="fields"
            primary-key="id"
            show-empty
            empty-text="Нет подходящих результатов"
        >

          <!-- Шапка TrashIcon -->
          <template #head(isDeleted)="data">
            <feather-icon icon="Trash2Icon" size="20"/>
          </template>

          <!-- Шапка TrashIcon -->
          <template #head(discount)="data">
            <div align="center"> %</div>
          </template>

          <!---------  Ячейки --------------->

          <template #cell(position)="data">
            {{ calculatePosition(data.index) }}
          </template>
          <!-- Артикул -->
          <template #cell(vendor_code)="data">
            {{ data.item.product.vendor_code }}
          </template>

          <!-- Название -->
          <template #cell(name)="data">
            <p @click="showProductPreview(data.item)" class="cursor-pointer">
              {{ data.item.product.name }}
            </p>
          </template>

          <!-- Наличие -->
          <template #cell(stock)="data">
            {{ data.item.product.count }}
          </template>

          <!-- Количество -->
          <template #cell(count)="data">
            <div style="width: fit-content;">
              <BlSpinButton
                  :value="data.item.count"
                  :max="999999"
                  :step="data.item.product.multiplicity"
                  @input="changeAreaItemCount(data.item, $event)"


              >
              </BlSpinButton>

            </div>


          </template>

          <!-- Скидка -->
          <template #cell(discount)="data">
            <BlInput class="number-input"
                     style="min-width: 60px"
                     v-model="data.item.discount"
                     placeholder="10"></BlInput>
          </template>

          <!-- Цена -->
          <template #cell(price)="data">
            <div class="d-flex">
              <b-form-input
                  style="min-width: 75px"
                  :value="data.item.price| formatNumber"
                  @input="data.item.custom.price = $event"
                  @change="changeAreaItemCustomPrice(data.item, $event)"
                  v-b-tooltip.hover
                  :title="data.item.custom_price ? 'Кастомная цена' : 'Из прайс листа'"
                  type="number"
              ></b-form-input>
              <BlPriceHistorySelect
                  v-b-tooltip.hover
                  title="История цен"
                  :items="data.item.price_history"
                  @input="changeAreaItemCustomPrice(data.item, $event)"
              ></BlPriceHistorySelect>
            </div>

          </template>

          <!-- Стоимость -->
          <template #cell(cost)="data">
            {{ (data.item.custom_price ? data.item.custom_price : data.item.price) * data.item.count | formatNumber }}
          </template>

          <!-- Удалить -->
          <template #cell(isDeleted)="data">
            <b-form-checkbox
                @change="selectItem(data.item, $event)"></b-form-checkbox>
          </template>

          <template #cell(move)="data">
            <div class="d-flex align-items-center">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="upProduct(data.item)"
                  v-b-tooltip.hover title="Поднять товар"
                  style="background-color: #fff !important; color: #6e6b7b !important; border: none"
              >
                <feather-icon icon="ArrowUpIcon"size="20"/>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="downProduct(data.item)"
                  v-b-tooltip.hover title="Опустить товар"
                  style="background-color: #fff !important; color: #6e6b7b !important; border: none"
              >
                <feather-icon icon="ArrowDownIcon" size="20" />
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="setZonePosition(data.item)"
                  v-b-tooltip.hover title="Указать позицию"
                  style="background-color: #fff !important; color: #6e6b7b !important; border: none"
              >
                <feather-icon icon="HashIcon" size="20"/>
              </b-button>
            </div>
          </template>


        </b-table>

      </transition>
      <div class="d-flex justify-content-end">
        <b-button
            v-if="selectedItems.length>0"
            variant="primary"
            :disabled="selectedItems.length===0"
            class="mr-1"
            @click="copySelectedProducts"
        >
          Копировать выбранные товары
        </b-button>
        <b-button
            v-if="selectedItems.length>0"
            variant="danger"
            @click="removeSelectedItems"
            :disabled="selectedItems.length===0"
        >
          Удалить выбранные товары
        </b-button>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import BlInput from "@/components/controls/BlInput";
import BlBreadcrumb from "@/components/core/BlBreadcrumb";
import {
  BFormInput,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BButtonGroup,
  BButton,
  BTable,
  BOverlay,
  BLink,
} from 'bootstrap-vue'
import ModalCustomProduct from "@/components/views/projects/modals/ModalCustomProduct";
import ModalViewProduct from "@/components/views/projects/modals/ModalViewProduct";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import utils from "@/utils";
import Vue from "vue";

import BlSpinButton from "@/components/controls/BlSpinButton";
import BlPriceHistorySelect from "@/components/core/BlPriceHistorySelect.vue";

export default {
  name: "Zone",
  directives: {
    Ripple,
  },
  components: {
    BlPriceHistorySelect,
    BFormInput,
    BlBreadcrumb,
    vSelect,
    BlInput,
    BFormCheckbox,
    ModalCustomProduct,
    ModalViewProduct,
    BRow,
    BCol,
    BFormGroup,
    BButtonGroup,
    BButton,
    BTable,
    BOverlay,
    BLink,
    BlSpinButton,
  },

  computed: {
    sortItems: function() {
      if (this.zone.items_order.length !== this.zone.items.length) {
        this.zone.items_order = this.zone.items.map((item) => item.id)
      }

      return this.zone.items_order.map((id) => this.zone.items.find(item => item.id === id))
    },
  },

  methods: {
    calculatePosition(index) {
      // Add 1 because positions are usually 1-based, not 0-based
      return index + 1;
    },
    insertCopiedItems() {
      const tasks = []
      for (const id of this.$store.state.productClipboard.productIds) {
        const task = api.projects.addToZone(1, id, this.zone.id, this.$router.currentRoute.params.id, this.zone.room)
        tasks.push(task)
      }
      Promise.all(tasks).then((response) => {
        utils.notificate(this, "Товары скопированы")
      })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          }).finally(() => {
        this.updateProjectInfo()
        this.$store.dispatch('productClipboard/setProducts', [])
      });
    },
    copySelectedProducts() {
      // this.selectedItems.push({
      //   zoneId: item.area.room,
      //   areaId: item.area.id,
      //   areaItemId: item.id,
      //   count: item.count,
      //   product: item.product,
      // })
      this.$store.dispatch(
          'productClipboard/setProducts',
          this.selectedItems.map(item => item.product.id)
      )
    },
    goToPickProducts() {
      // { path: '/price', query: { addProductToZone: 'true', zone: `${this.zone.id}`, room: `${this.zone.room}`, project: `${this.$router.currentRoute.params.id}` }}
      this.$store.dispatch('app.products/setProductPickMode', true)
      const url = `/price?addProductToZone=${true}&zone=${this.zone.id}&room=${this.zone.room}&project=${this.$router.currentRoute.params.id}`
      this.$router.push(url)
    },
    copyZoneId(id) {
      this.$store.commit('app/SET_CLIPBOARD_ZONE_ID', {
        projectId: this.routeId,
        roomId: this.zone.room,
        zoneId: this.zone.id
      })
      utils.notificate(this, "Вы скопировали зону. Теперь можете открыть нужный проект, помещение и вставить эту зону")
    },
    upZone() {
      this.$emit('upZone', this.zone)
    },
    downZone() {
      this.$emit('downZone', this.zone)
    },
    copyZone() {
      this.$emit('copyZone', {id: this.zone.id, room_pk: this.zone.room, project_pk: this.routeId})
    },

    deleteZone() {
      this.$emit('deleteZone', {id: this.zone.id, room_pk: this.zone.room, project_pk: this.routeId})
    },
    setZonePosition(item) {
      this.moveItem = item;
      this.changePosition = true;
    },
    renameZone() {
      this.name = this.zone.title
      this.editing = true
    },
    blurNameZone() {
      this.dataLoading = true
      const temp = this.zone.title
      if (this.name == '') {
        this.zone.title = temp
      } else {
        this.zone.title = this.name
      }

      api.projects
          .renameZone(this.routeId, this.zone.room, this.zone.id, this.zone.title)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.updateProjectInfo()
              this.dataLoading = true
              utils.notificate(this, data.message)
              this.dataLoading = false
              this.$forceUpdate()
              this.$emit('getZones')
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
      this.editing = false
    },
    leaveZoneComment() {
        this.message = this.zone.comment
        this.commenting = true
    },
    blurCommentZone() {
        this.dataLoading = true
        const empty = ''
        if (this.message == '') {
            this.zone.comment = empty
        } else {
            this.zone.comment = this.message
        }

        api.projects
            .leaveZoneComment(this.routeId, this.zone.room, this.zone.id, this.zone.title, this.zone.comment)
            .then((response) => {
                const data = response.data;
                if (data.error) {
                    throw "Ошибка";
                } else {
                    this.updateProjectInfo()
                    this.dataLoading = true
                    utils.notificate(this, data.message)
                    this.dataLoading = false
                    this.$forceUpdate()
                    this.$emit('getZones')
                }
            })
            .catch((err) => {
                this.dataLoading = false
                const data = err.response.data;
                if (data && data.error) {
                    utils.notificateError(self, data.message, data.errorCode);
                }
            });
        this.commenting = false
    },
    changeVisibleZone() {
      this.dataLoading = true
      console.log('Change visible', !this.zone.is_visible)
      this.zone.is_visible = !this.zone.is_visible
      api.projects
          .changeVisibleZone(this.routeId, this.zone.room, this.zone.id, this.zone.is_visible)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.updateProjectInfo()
              this.dataLoading = true
              this.$forceUpdate()
              this.dataLoading = false
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(this, data.message, data.errorCode);
            }
          });
    },


     addNewCustomProduct(data) {
      this.updateProjectInfo()
      // let temp = {
      //   id: this.zone.items.length + 1,
      //   name: data.product.name,
      //   article: '00000000',
      //   stock: 0,
      //   count: data.product.count,
      //   discount: 0,
      //   price: data.product.price,
      //   cost: data.product.price * data.product.count,
      //   isDeleted: false,
      // }
      // this.zone.items.push(temp)
    },
    updateProjectInfo() {
      this.dataLoading = true
      api.projects
          .getProject(this.routeId)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.$store.commit("app/SET_PROJECT", data.result)
              this.$store.commit('app/UPDATE_SELECTED_ZONE');
              this.$forceUpdate()
              this.dataLoading = false;
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    changeAreaItemCustomPrice(item, customPrice) {
      this.dataLoading = true
      api.projects.updateItem(item.id, customPrice)
          .then((response) => {
            this.updateProjectInfo()
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.dataLoading = false;
            }
          })
          .catch((err) => {
            this.dataLoading = false;
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });

    },
    changeAreaItemCount(item, newCount) {
      const deltaCount = newCount - item.count
      const self = this
      this.dataLoading = true
      api.projects
          .addToZone(deltaCount, item.product.id, item.area.id, this.routeId, item.area.room)
          .then((response) => {
            this.updateProjectInfo()
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.dataLoading = false;
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    removeItem(zoneId, areaId, areaItemId) {

      for (let idx = 0; idx < this.selectedItems.length; idx++) {
        const item = this.selectedItems[idx]
        if ((item.zoneId === zoneId) && (item.areaId === areaId) && (item.areaItemId === areaItemId)) {
          this.selectedItems.splice(idx, 1)
          return

        }
      }

    },
    selectItem(item, value) {
      console.log(item, value)
      this.removeItem(item.area.room, item.area.id, item.id)
      if (value) {
        this.selectedItems.push({
          zoneId: item.area.room,
          areaId: item.area.id,
          areaItemId: item.id,
          count: item.count,
          product: item.product,
        })

      }


    },
    showProductPreview(item) {
      this.productItem = item.product
      this.isActiveViewProduct = !this.isActiveViewProduct
    },
    upProduct(item) {
      const first = this.zone.items_order.indexOf(item.id)

      if (first !== 0) {
        let origin = this.zone.items_order[first]
        this.zone.items_order[first] = this.zone.items_order[first - 1]
        Vue.set(this.zone.items_order, first - 1, origin)

        api.projects
            .updateProductOrder(this.routeId, this.zone.room, this.zone.id, this.zone.title, this.zone.items_order)
            .then((response) => {
              const data = response.data;
              if (data.error) {
                throw "Ошибка";
              } else {
                this.updateProjectInfo()
                this.$forceUpdate()
                this.$emit('getZones')
              }
            })
            .catch((err) => {
              this.dataLoading = false
              const data = err.response.data;
              if (data && data.error) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            });
      } else {
        utils.notificate(this, "Товар первый в списке.")
      }
    },
    downProduct(item) {
      const first = this.zone.items_order.indexOf(item.id)
      const end = this.zone.items_order.at(-1)
      const last = this.zone.items_order.indexOf(end)

      if (first !== last) {
        let origin = this.zone.items_order[first]
        this.zone.items_order[first] = this.zone.items_order[first + 1]
        Vue.set(this.zone.items_order, first + 1, origin)

        api.projects
            .updateProductOrder(this.routeId, this.zone.room, this.zone.id, this.zone.title, this.zone.items_order)
            .then((response) => {
              const data = response.data;
              if (data.error) {
                throw "Ошибка";
              } else {
                this.updateProjectInfo()
                this.$forceUpdate()
                this.$emit('getZones')
              }
            })
            .catch((err) => {
              this.dataLoading = false
              const data = err.response.data;
              if (data && data.error) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            });
      } else {
        utils.notificate(this, "Товар последний в списке.")
      }
    },
    setProductPosition() {
      const from = this.zone.items_order.indexOf(this.moveItem.id);
      const to = this.position - 1;
      this.zone.items_order.splice(to, 0, this.zone.items_order.splice(from, 1)[0]);

      api.projects
          .updateProductOrder(this.routeId, this.zone.room, this.zone.id, this.zone.title, this.zone.items_order)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.changePosition = false;
              this.updateProjectInfo()
              this.$forceUpdate()
              this.$emit('getZones')
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    async removeSelectedItems() {
      const self = this;
      this.dataLoading = true

      for (let item of this.selectedItems) {
        await api.projects.deleteItem(item.areaItemId)

      }
      this.dataLoading = false
      this.selectedItems = []
      this.updateProjectInfo()


    }

  },
  mounted() {
    this.routeId = this.$router.currentRoute.params.id;
  },

  props: ['zone'],
  data() {
    return {
      dataLoading: false,
      isActiveCustomProduct: false,
      isActiveViewProduct: false,
      productItem: {},
      editing: false,
      commenting: false,
      name: '',
      message: '',
      changePosition: false,
      moveItem: {},
      position: 0,
      routeId: null,
      selectedItems: [],
      fields: [
        {
          key: 'position',
          label: '№',
          sortable: false,
          class: 'product-table-id'
        },
        {
          key: 'vendor_code',
          label: 'Артикул',
          sortable: false,
          class: 'product-table-number'
        },
        {
          key: 'name',
          label: 'Наименование',
          sortable: false,
          class: 'product-table-name'
        },
        {
          key: 'stock',
          label: 'Наличие',
          sortable: false,
          class: 'product-table-quantity'
        },
        {
          key: 'count',
          label: 'Кол-во',
          sortable: false,
          class: 'product-table-count'
        },
        // {key: 'discount', label: '%', sortable: false},
        {
          key: 'price',
          label: 'Цена',
          sortable: false,
          class: 'product-table-price'
        },
        {
          key: 'cost',
          label: 'Стоимость',
          sortable: false,
          class: 'product-table-amount'
        },
        {
          key: 'isDeleted',
          icon: 'feather icon-home',
          sortable: false,
          class: 'product-table-del'
        },
        {
          key: 'move',
          label: 'Управление',
          sortable: false,
          class: 'product-table-move'
        },
      ]
    }
  }
}
</script>

<style>
.product-table-id {
  min-width: 60px !important;
  max-width: 60px !important;
  text-align: center;
}

.product-table-number {
  min-width: 100px !important;
  max-width: 100px !important;
  text-align: center;
}

.product-table-name {
  min-width: 300px !important;
  max-width: 300px !important;
}

.product-table-quantity {
  min-width: 80px !important;
  max-width: 80px !important;
  text-align: center;
}

.product-table-count {
  min-width: 150px !important;
  max-width: 150px !important;
  text-align: center;
}

.product-table-price {
  min-width: 150px !important;
  max-width: 150px !important;
  text-align: center;
}

.product-table-amount {
  min-width: 100px !important;
  max-width: 100px !important;
  text-align: center;
}

.product-table-del {
  min-width: 40px !important;
  max-width: 40px !important;
}
.product-table-move {
  min-width: 120px !important;
  max-width: 120px !important;
}
</style>
