<template>
  <b-row
      v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
      class="content-header"
  >

    <!-- Content Left -->
    <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
    >
      <b-row class="breadcrumbs-top">
          <div class="breadcrumb-wrapper">
            <b-breadcrumb class="font-medium-3">
              <b-breadcrumb-item
                  v-for="item in $route.meta.breadcrumb"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
              >
                {{ item.text}}
              </b-breadcrumb-item>
               <slot></slot>
            </b-breadcrumb>
          </div>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {BBreadcrumb, BBreadcrumbItem, BButton, BCol, BDropdown, BDropdownItem, BRow} from "bootstrap-vue";
import router from "@/router";

export default {
  name: "BlMiniBreadcrumb",
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  computed: {
  }
}

</script>

<style scoped>

</style>
