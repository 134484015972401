<template>
  <div class="sidebar-detached sidebar-left ">
    <b-card class="w-100">

      <bl-three-view :items="$store.state.app.project.rooms" :depth="3"></bl-three-view>


    </b-card>

  </div>
</template>

<script>
import {BCard} from "bootstrap-vue";
import BlThreeView from "@/components/controls/BlThreeView";

export default {
  name: "LeftTree",
  components: {
    BCard,
    BlThreeView,
  },
  data: function () {
    return {
      items: [
        {
          id: 1,
          title: 'Room 1',
          items: [
            {
              id: 1,
              title: 'Area 1',
            },
            {
              id: 2,
              title: 'Area 2',
            },
            {
              id: 3,
              title: 'Area 3',
            }
          ]
        },
        {
          title: 'Room 2',
          id: 2,
          items: [
            {
              id: 4,
              title: 'Area 1',

            },
            {
              id: 5,
              title: 'Area 2',
            }
          ]
        }
      ]
    }
  }

}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style scoped>
.card-body {
  padding-left: 0px;


}

</style>
