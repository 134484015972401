<template>
  <div v-if="project">
    <h5 class="bl-three-view-title ml-2">Структура проекта</h5>
    <bl-three-view-item
        :id="project.id"
        :title="project.title"
        :total_price="total_price"
        :items="project.rooms"
        type="project"
        :depth="depth-1"
        :offset="1"
        :isSelected="$store.state.app.selectedProjectZone.id==project.id"
        :object="project"


    ></bl-three-view-item>

  </div>


</template>

<script>
import BlThreeViewItem from "@/components/controls/BlThreeViewItem";

export default {
  name: "BlThreeView",
  components: {
    BlThreeViewItem,
  },
  computed: {
    project: function () {
      return this.$store.state.app.project;
    },
    realDiscountCoef() {
      const discount = this.$store.state.app.project.discount
      return 1.0 - (Math.min(100.0, Math.max(0.0, discount)) / 100.0)
    },
    total_price: function() {
      return this.$store.state.app.project.total_price * this.realDiscountCoef
    }

  },
  props: [
    'depth',

  ],
  data: function () {
    return {}
  },

}
</script>

<style scoped>
.bl-three-view-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11pt;
  line-height: 23px;
  /* identical to box height, or 192% */

  letter-spacing: 0.6px;
  text-transform: uppercase;
}
</style>
