<template>
  <b-dropdown :disabled="items.length < 2" size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
    <template #button-content>
      <div class="d-flex " style="align-items: center">
        <feather-icon icon="ClockIcon"/>
        <feather-icon icon="ChevronDownIcon"/>
      </div>
    </template>
    <b-dropdown-item
        v-for="item in items"
        @click="$emit('input', item.price)"
    >
      <div class="d-flex flex-column">
        <div style="font-size: 0.8rem">
          {{ utils.humanize(item.created_at) }}

        </div>
        <div style="font-size: 0.65rem">
          Цена: {{ item.price }}

        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from 'bootstrap-vue'
import utils from '@/utils'
export default {
  name: "BlPriceHistorySelect",
  components: {
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  props: {
    items: {
      default: () => ([])
    }
  },
  data: ()=>({
    utils,
  })
}
</script>

<style scoped>

</style>
