<template>
  <div class="position-relative" @click="select">
    <div v-if="isSelected"
         class="three-item-left-border"></div>
    <div :class="isSelected ? `bl-three-view-item-root bl-three-view-item-root-active` : `bl-three-view-item-root`"
         :style="`padding-left: ${20*offset}px;`">
      <div v-if="type === 'project'" class="sum">
        <div style="width: 230px">
          <img src="/icons/project-icon.svg" class="mr-1">
          <span class="mr-1">{{ title }}</span>
        </div>
        <div class="ml-2" style="width: 100px">{{ total_price | formatNumber}} {{ this.ruble === 'BYN' ? 'Br' : '₽'}}</div>
      </div>
      <div v-if="type === 'room'" class="sum">
        <div style="width: 210px">
          <img src="/icons/room-icon.svg" class="mr-1">
          <span class="mr-1">{{ title }}</span>
        </div>
        <div class="ml-2" style="width: 100px">{{ total_price | formatNumber}} {{ this.ruble === 'BYN' ? 'Br' : '₽'}}</div>
      </div>
      <div v-if="type === 'area'" class="sum">
        <div style="width: 190px">
          <img src="/icons/area-icon.svg" class="mr-1">
          <span class="mr-1">{{ title }}</span>
        </div>
        <div class="ml-2" style="width: 100px">{{ total_price | formatNumber}} {{ this.ruble === 'BYN' ? 'Br' : '₽'}}</div>
      </div>
    </div>

    <bl-three-view-item v-if="depth > 0" v-for="item in items" :key="item.id"
        :id="item.id"
        :total_price="item.total_price"
        :title="`${item.title || item.name}`"
        :items="item.areas"
        :type="item.type"
        :depth="depth-1"
        :offset="offset+1"
        :isSelected="isSelected ||  (($store.state.app.selectedProjectZone.id == item.id) && ($store.state.app.selectedProjectZone.type == item.type))"
        :object="item"

    ></bl-three-view-item>
  </div>
</template>

<script>

export default {
  name: "BlThreeViewItem",
  props: [
    'type',
    'id',
    'title',
    'total_price',
    'items',
    'depth',
    'offset',
    'isSelected',
    'object',

  ],
  computed: {
    ruble() {
      return localStorage.getItem('user.currency')
    },
    subItems: (item) => {
      // if (type === "project") {
      //   return item.areas;
      // }
    }

  },
  data: function () {
    return {}
  },
  methods: {
    select(event) {
      console.log(this.id)
      this.$store.commit('app/SET_SELECTED_PROJECT_ZONE', this.object)
      event.stopPropagation()


    }
  },
}
</script>

<style scoped lang="scss">
@import "src/@core/scss/core";

.bl-three-view-item-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 8pt;
  flex-grow: 1;


}

.three-item-left-border {
  width: 3px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $primary;
  border-radius: 4px;
  box-sizing: border-box;
}

.bl-three-view-item-root-active {
  /*background-color: #efedff;*/

  color: $primary;
  //color: #7367f0;
  font-weight: bold;
}

.bl-three-view-item-root:hover {
  /*background-color: #efedff;*/
  color: $primary;
  font-weight: bold;
}
.sum {
  display: flex;

  @media(max-width: 1639px) {
    display: block;
  }
  @media(max-width: 961px) {
    display: flex;
  }
}
</style>
