<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Добавить кастомный товар
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <validation-observer
          v-slot="{invalid}"
      >
        <!-- Форма -->
        <b-form
            class="p-2"
            @submit.prevent
        >
          <!-- Инпуты -->
          <BlInput placeholder="Лампа эдисона"
                   label="Наименование"
                   rules="required"
                   v-model="product.name">
          </BlInput>
          <BlTextArea
                      label="Описание"
                      rows="4"
                      max-rows="4"
                      placeholder="Описание или технические характеристики товара"
                      v-model="product.description">
          </BlTextArea>
          <BlInlinePictureForm @outputFile="outputFile"></BlInlinePictureForm>
          <div class="mt-1">
            <label for="uom">Единицы измерения</label>
            <v-select
                id="uom"
                v-model="product.uom.selected"
                :options="product.uom.options"
                :clearable="false"
                :messages="product.uom.selected.label"
            />
          </div>
          <BlInput placeholder="9999"
                   label="Цена за единицу (Br)"
                   rules="required"
                   class="mt-1"
                   inputType="number"
                   v-model.number="product.price">
          </BlInput>
          <div class="mt-1 d-flex justify-content-start" style="max-width: 150px">
            <BlSpinButton
                :value="product.count"
                :max="999999"
                @input="changeCounter(product, $event)"
            >
            </BlSpinButton>
          </div>

          <!-- Кнопки -->
          <div class="d-flex mt-2 ">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="button"
                @click="createCustomProduct"
                :disabled="invalid"
            >
              Добавить
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              Отменить
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import BlInlinePictureForm from "@/components/controls/BlInlinePictureForm";

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import BlInput from "@/components/controls/BlInput";
import BlTextArea from "@/components/controls/BlTextArea";
import BlSpinButton from "@/components/controls/BlSpinButton";
import '@/libs/custom-validate'
import api from "@/api";
import utils from "@/utils";
import axios from "axios";
import router from "@/router";


export default {
  components: {
    BlInlinePictureForm,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BlInput,
    BlTextArea,
    BlSpinButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    changeCounter(_, value) {
      this.product.count = value
    },

    createCustomProduct(){
      let product = null
      let image = null
      api.files
          .upload(this.product.file)
          .then((response) => {
            image = response.data.result;
            console.log(image)
            if (!image.error){
              api.store.createCustomProduct({
                name: this.product.name,
                description: this.product.description,
                storageUnit: this.product.uom.selected.label,
                price: this.product.price,
                imageUrl: image.path,
              })
              .then(response=>{
                const product = response.data.result;
                if (!product.error){
                  api.projects.addToZone(
                      this.product.count,
                      product.id,
                      this.zone.id,
                      this.$store.state.app.project.id,
                      this.zone.room
                  )
                  .then(response=>{
                    const data = response.data.result;

                    if (!data.error){
                      utils.notificate(this, "Товар успешно добавлен")
                      this.$emit('added')
                    }

                  })
                }
              })

            }
          })
          .catch((err) => {
            // const data = err.response.data;
            console.log(err)
            // if (data && data.message) {
            //   utils.notificateError(self, data.message, data.errorCode);
            // }
          });


    },
    outputFile(data){
      this.product.file = data.file
    }
  },
  directives: {
    Ripple,
  },
  props: ['isActive', 'zone'],
  data() {
    return {
      product: {
        name: '',
        description: '',
        file: null,
        uom: {
          options: [
            {
              value: 1, label: 'Шт'
            },
            {
              value: 2, label: 'М'
            },
          ],
          selected: {
            value: 1, label: 'Шт'
          }
        },
        count: 1,
        price: 0,
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
